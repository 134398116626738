import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { Field } from 'formik'
import './styles.scss'

const Error = loadable(() => import('/src/components/form-components/Error'))
const Required = loadable(() =>
    import('/src/components/form-components/Required/Required')
)

const TextField = ({
    label,
    name,
    type,
    format,
    placeholder,
    ref,
    isSubmitting,
    isSubmitted,
    className,
    required
}) => {
    return (
        <div
            className={`c-textfield form-group${className && ` ${className}`}`}
        >
            <label htmlFor={name}>
                {label}
                {required && <Required />}
            </label>

            <Field
                type={type ? type : 'text'}
                inputMode={format === 'number' ? 'numeric' : 'text'}
                pattern={format === 'number' ? '[0-9 ]+' : '.*'}
                name={name}
                placeholder={placeholder}
                innerRef={ref}
                className="form-control"
                disabled={isSubmitting || isSubmitted}
            />

            <Error name={name} />
        </div>
    )
}

TextField.propTypes = {
    /**
     * Field label
     */
    label: PropTypes.string.isRequired,
    /**
     * Field name
     */
    name: PropTypes.string.isRequired,
    /**
     * Field type
     */
    type: PropTypes.oneOf(['email', 'hidden']),
    /**
     * Format
     */
    format: PropTypes.oneOf(['number']),
    /**
     * Field placeholder
     */
    placeholder: PropTypes.string,
    /**
     * Optional field ref
     */
    ref: PropTypes.string,
    /**
     * Whether form is submitting
     */
    isSubmitting: PropTypes.bool.isRequired,
    /**
     * Whether form is submitted
     */
    isSubmitted: PropTypes.bool.isRequired,
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Whether field is required
     */
    required: PropTypes.bool
}

TextField.defaultProps = {
    label: 'First name',
    name: 'firstname',
    isSubmitting: false,
    isSubmitted: false,
    required: false
}

export default TextField
